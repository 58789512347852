import { connect } from 'react-redux';
import App from '../../Components/App';
import { getAppConfig } from '../../reducers/appConfig';
import { setAuth } from '../../reducers/auth';

const mapStateToProps = (state, ownProps) => ({
  appConfig: state.appConfig,
  auth: state.auth,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getAppConfig(...args) {
    return getAppConfig(dispatch, ...args);
  },
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import Config from '../Config';

export function physicalLocationDefaultState() {
  return {};
}

export const defaultSort = {
  key: 'name',
  headerKey: 'name',
  reverse: false
};

const sortQuery = (sort = defaultSort) => {
  if (sort.reverse === true) {
    return `-${sort.key}`;
  }

  return sort.key;
};

const serialize = (filter = {}, prefix = '?') => {
  const query = [];

  Object.keys(filter).forEach((key) => {
    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`);
  });

  if (query.length === 0) {
    return '';
  }

  return `${prefix}${query.join('&')}`;
};

const serialisePageNumber = (pageNumber) => {
  if (!pageNumber) {
    return '';
  }
  return `&page.number=${pageNumber}`;
};

const serialisePageSize = (pageSize) => {
  if (!pageSize) {
    return '';
  }
  return `&page.size=${pageSize}`;
};

async function api(dispatch, token, params) {
  const {
    query = null,
    endpoint = 'physical-location',
    method = 'GET',
    body = null,
    contentType = 'application/json'
  } = params;

  const fetchConfig = {
    method,
    headers: {
      'content-type': contentType,
      authorization: token
    }
  };

  if (body) {
    if (contentType === 'application/json') {
      fetchConfig.body = JSON.stringify(body);
    } else {
      fetchConfig.body = body;
    }
  }

  let fetchUrl = `${Config.apiGateway.PHY_LOCATION_APIGATEWAY_URL}/${endpoint}`;
  if (query) {
    fetchUrl += query;
  }

  const result = await fetch(
    fetchUrl,
    fetchConfig
  );

  const payload = await result.json();

  if (!result.ok) {
    throw Object.assign(new Error(), {
      status: result.status,
      message: payload.errors
    });
  }

  return payload;
}

export async function getPhysicalLocationList(dispatch, token, filter, sort, pageSize, pageNumber) {
  let query = `?${serialisePageNumber(pageNumber)}${serialisePageSize(pageSize)}&internal.returnMetadata=true${serialize(filter, '&')}`;
  if (sort) {
    query += `&sort=${sortQuery(sort)}`;
  }

  return api(dispatch, token, { query });
}

export async function getPhysicalLocationStructure(dispatch, token, companyUuid, queryParams) {
  const params = {
    query: serialize(queryParams),
    endpoint: `physical-location-structure/${companyUuid}`
  };

  return api(dispatch, token, params);
}

export async function exportList(dispatch, token, filter, sort) {
  let query = `${serialize(filter, '?')}`;
  if (sort) {
    query += `${query ? '&' : '?'}sort=${sortQuery(sort)}`;
  }
  const params = {
    query,
    endpoint: 'physical-location-export-list'
  };
  return api(dispatch, token, params);
}

export async function exportStructure(dispatch, token, filter, companyUuid, parentUuid) {
  let query = `${serialize(filter, '?')}`;
  if (parentUuid) {
    query += `${query ? '&' : '?'}filter.parentUuid=${parentUuid}`;
  }
  const params = {
    query,
    endpoint: `physical-location-export-structure/${companyUuid}`
  };
  return api(dispatch, token, params);
}

export async function getExportTransactionStatus(dispatch, token, transactionId) {
  const params = {
    endpoint: `physical-location-export-transaction/${transactionId}`
  };
  return api(dispatch, token, params);
}

export function getCompanyName(state, id) {
  return state.normalizedData.entities.company[id];
}

export function getPhysicalLocationName(state, id) {
  return state.normalizedData.entities.company[id];
}

// Batch Validation (upload)
export async function batchXlsxUpload(dispatch, token, file) {
  const params = {
    method: 'POST',
    endpoint: 'batch-xlsx-processor',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    body: file
  };

  return api(dispatch, token, params);
}

// Batch Submission (by filename)
export async function submitBatch(dispatch, token, payload) {
  const params = {
    method: 'POST',
    endpoint: 'batch-physical-location',
    body: {
      data: payload
    }
  };

  return api(dispatch, token, params);
}

// Batch transaction status by type: 'batchValidation', 'batchUpsert'
export async function getBatchStatus(dispatch, transactionType, token, transactionId) {
  const params = {
    method: 'GET',
    endpoint: 'batch-status',
    query: `?transactionType=${transactionType}&transactionId=${transactionId}`
  };

  return api(dispatch, token, params);
}

// Batch transaction status by type: 'batchValidation', 'batchUpsert'
export async function getBatchTransactions(dispatch, token) {
  const params = {
    method: 'GET',
    endpoint: 'batch-transactions'
  };

  return api(dispatch, token, params);
}

// GET /batch-xlsx-log/{transactionId}/{locale}
export async function getXlsxLogForBatchTransaction(dispatch, token, transactionId, locale) {
  const params = {
    method: 'GET',
    endpoint: `batch-xlsx-log/${transactionId}/${locale}`
  };

  return api(dispatch, token, params);
}

export default (state = physicalLocationDefaultState(), action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

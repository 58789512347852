import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Row, Col, Divider, Button
} from 'antd';
import { Link } from 'react-router-dom';

import { BatchTransactionView } from 'componentlibrary';
import Base from '../Base';
import { getCurrentLanguage } from '../../translations/languageOptions';

export const INITIATED = 'INITIATED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const COMPLETE = 'COMPLETE';
export const ERROR = 'ERROR';

export const statusMap = {
  BATCH_WRITE_INITIATED: INITIATED,
  ENQUEUED: IN_PROGRESS,
  BATCH_WRITING: IN_PROGRESS,
  BATCH_WRITING_FINISHED: COMPLETE,
  BATCH_WRITING_FINISHED_WITH_ERROR: COMPLETE,
  BATCH_WRITING_UNEXPECTED_ERROR: ERROR,
};

export class PhysicalLocationTransactionView extends Base {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      status: null,
      total: 0,
      success: 0,
      failed: 0
    };

    this.getSuccessLogFile = this.getSuccessLogFile.bind(this);
    this.getFailLogFile = this.getFailLogFile.bind(this);
  }

  get transactionUuid() {
    const { match } = this.props;
    return match.params.uuid;
  }

  componentDidMount() {
    this.getSubmissionStatus();
    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async getSuccessLogFile() {
    const response = await this.getLogFileUrl();
    return response.downloadUrlForSuccessLog;
  }

  async getFailLogFile() {
    const response = await this.getLogFileUrl();
    return response.downloadUrlForFailLog;
  }

  async getLogFileUrl() {
    const { i18n, getXlsxLogForBatchTransaction } = this.props;
    const currentSelectedLocale = getCurrentLanguage(i18n.languages);

    const response = await this.dispatchWithAuth(
      getXlsxLogForBatchTransaction,
      this.transactionUuid,
      currentSelectedLocale.key
    );

    return response;
  }

  async getSubmissionStatus() {
    const { t, getBatchSubmissionStatus } = this.props;
    let result;

    try {
      result = await this.dispatchWithAuth(
        getBatchSubmissionStatus,
        this.transactionUuid
      );
    } catch ({ status }) {
      if (status !== 401) {
        super.handleError(
          t(status === 404 ? 'transaction:notFound' : 'transaction:fetchError'),
          t('common:error')
        );
      }
      return;
    }

    const { attributes } = result;
    const status = statusMap[attributes.status];

    const {
      total,
      success,
      fail,
      initiatedBy,
      initiatedAt,
      updatedAt
    } = attributes;

    this.setState({
      loading: false,
      error: this.errorResponse(status),
      status,
      total,
      success,
      fail,
      initiator: initiatedBy,
      createdAt: initiatedAt,
      updatedAt
    });

    if (this.stopPolling(status, total, success, fail)) {
      return;
    }

    this.getSubmissionStatus();
  }

  stopPolling(status, total, success, fail) {
    if (status === ERROR || status === COMPLETE || total === success + fail || this.unmounted) {
      return true;
    }

    return false;
  }

  errorResponse(status) {
    const { t } = this.props;

    if (status === ERROR) {
      return t('transaction:transactionError');
    }

    return null;
  }

  render() {
    const {
      loading,
      error,
      total,
      success,
      fail,
      initiator,
      createdAt,
      updatedAt
    } = this.state;

    const { t } = this.props;

    return (
      <>
        <Row>
          <Col>
            <h1>{`${t('transaction:title')} ${this.transactionUuid}`}</h1>
            <Divider />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <Link to="/physical-location/batch-process">
              <Button type="primary" icon="upload">{t('physicalLocations:newBatchProcess')}</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <BatchTransactionView
              loading={loading}
              error={error}
              transaction={{
                id: this.transactionUuid,
                initiatedBy: initiator,
                total,
                success,
                fail,
                createdAt,
                updatedAt
              }}
              logFiles={{
                success: this.getSuccessLogFile,
                fail: this.getFailLogFile
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

PhysicalLocationTransactionView.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getBatchSubmissionStatus: PropTypes.func.isRequired
};

export default withTranslation(['common', 'transaction', 'physicalLocations'])(PhysicalLocationTransactionView);
